<template>
  <div>
    <div class="displayDesctop">
      <div
        class="d-flex flex-wrap justify-center"
        v-if="serviceList.length > 0"
      >
        <div
          class="items mx-4"
          v-for="(item, index) in serviceList"
          :key="index"
        >
          <ServiceItem :item="item" />
        </div>
      </div>
      <EmptyService v-else />
    </div>
    <div class="sliderWrapper displayMobile">
      <splide :options="options" class="itemSplideWrapper"  v-if="serviceList.length > 0">
        <splide-slide v-for="(item, index) in serviceList" :key="index">
          <ServiceItem :item="item" />
        </splide-slide>
      </splide>
      <EmptyService v-else />
    </div>
  </div>
</template>
<script>
import ServiceItem from "./ServiceItem";
import EmptyService from "./EmptyService";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
export default {
  components: {
    ServiceItem,
    Splide,
    SplideSlide,EmptyService
  },
  computed: {
    serviceList() {
      return this.$store.getters.getServices;
    },
  },
  data() {
    return {
      options: {
        type: "loop",
        perPage: 1,
        // width  : 350,
        breakpoints: {
          600: {
            width: 400,
          },
          500: {
            width: 380,
          },
          400: {
            width: 350,
          },
          330: {
            width: 320,
          },
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "../assets/css/serviceslist.scss";
</style>
