<template>
  <div class="homeMain">
    <div class="servicePage" v-if="$store.getters.GET_CLIENT_LOGINED">
      <transition name="slide-fade">
        <div class="loginWrapper mx-auto my-7">
          <img class="greetingImage" src="../assets/img/girl.svg" />
          <h3 class="loginTitle">Вы авторизованы</h3>
          <div :class="drawnClass">
            <SvgTick />
          </div>
        </div>
      </transition>
      <div class="homeTitle">
        <span class="animateTitle">Наши сервисы</span>
      </div>
      <ServiceList />
      <!-- <div class="homeTitle">Вскоре добавим ещё :)</div> -->
    </div>
    <div class="loginBar" v-else>
      <WelcomeUserPage :itemData="itemData" />
    </div>
  </div>
</template>

<script>
import ServiceList from "@/components/ServiceList";
import WelcomeUserPage from "@/components/WelcomeUserPage";
import SvgTick from "@/components/SvgTick";

import { mapMutations } from "vuex";
export default {
  components: {
    ServiceList,
    SvgTick,
    WelcomeUserPage,
  },
  data: () => ({
    isAuth: false,
    drawnClass: "",
    itemData: {
      welcometext: "",
      description: ` Здесь вы получите доступ к сервисам GoMobile!
        Пожалуйста залогиньтесь`,
      isAdmin: false,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapMutations(["SET_COOKIE_AUTH"]),
    initialize() {
      if (this.$store.getters.GET_CLIENT_LOGINED) return;
      this.$store.dispatch("GET_LOGINED").then((el) => {
        this.getService();
      });
      setTimeout(() => {
        this.drawnClass = "drawn";
      }, 200);
      setTimeout(() => {
        this.show = false;
      }, 1200);
    },
    getService() {
      this.$store.dispatch("GET_SERVICE");
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/home.scss";
</style>
